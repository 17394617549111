import styled from "styled-components"

const Menu = styled.ul`
  position: absolute;
  left: 2rem;
  bottom: 3rem;
  background: ${({ theme }) => theme.color.white};
  padding: 1.6rem 2.4rem;
  display: none;
  list-style: none;
  margin: 0;

  ${({ isOpen }) => (isOpen ? `display: block` : `display: none`)};

  ${({ extraMenuStyles }) => extraMenuStyles}
`

const MenuItem = styled.li`
  color: ${({ isSelected, theme }) =>
    isSelected ? theme.color.main03 : theme.color.secondary01};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.color.main03};
  }

  margin-bottom: 1.6rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  ${({ extraMenuItemStyles }) => extraMenuItemStyles}
`

export { Menu, MenuItem }
