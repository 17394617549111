import React from "react"
import PropTypes from "prop-types"
import {
  StyledBanner,
  StyledContentWrapper,
  StyledContent,
  StyledCloseIcon,
  StyledCloseButton,
} from "./Banner.styles"

const Banner = ({ children, onClose, testId, ...pasedProps }) => {
  return (
    <StyledBanner data-testid={testId} {...pasedProps}>
      <StyledContentWrapper>
        <StyledContent>{children}</StyledContent>
        <StyledCloseButton onClick={onClose} data-testid="bannerCloseButton">
          <StyledCloseIcon />
        </StyledCloseButton>
      </StyledContentWrapper>
    </StyledBanner>
  )
}

Banner.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.shape({}),
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onClose: PropTypes.func.isRequired,
  testId: PropTypes.string.isRequired,
}

export default Banner
