import styled, { css } from "styled-components"

const StyledMain = styled.main`
  ${({ bannerHeight }) =>
    css`
      padding-top: ${bannerHeight}px;
    `}
`

const StyledBannerWrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 101;
`
export { StyledMain, StyledBannerWrapper }
