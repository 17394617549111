import React from "react"
import PropTypes from "prop-types"
import browserWindow from "utils/browserWindow"
import P from "components/atoms/P"
import {
  paragraphExtraStyles,
  StyledBanner,
  StyledButton,
} from "./GlobalBanner.styles"

const GlobalBanner = ({
  alertData: { message, buttonText, buttonDestination },
  handleClose,
}) => {
  const handleBannerClose = () => {
    browserWindow.sessionStorage.setItem("langBannerClicked", "true")
    handleClose()
  }

  return (
    <StyledBanner onClose={() => handleBannerClose()} testId="globalBanner">
      <P extraStyles={paragraphExtraStyles}>{message}</P>
      <StyledButton
        size="medium"
        type="secondary"
        onClick={handleBannerClose}
        testId="globalBannerButton"
        to={buttonDestination}
      >
        {buttonText}
      </StyledButton>
    </StyledBanner>
  )
}

GlobalBanner.propTypes = {
  handleClose: PropTypes.func.isRequired,
  alertData: PropTypes.shape({
    buttonDestination: PropTypes.string,
    buttonText: PropTypes.string,
    isPublished: PropTypes.bool,
    message: PropTypes.string,
  }).isRequired,
}

export default GlobalBanner
