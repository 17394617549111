import styled from "styled-components"

const StyledPanel = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.2s ease-in-out;
`
const StyledWrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  border: 1px solid transparent;
  flex-direction: column;
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.color.secondary04};

  ${({ accordionStyles }) => accordionStyles}
`

export { StyledWrapper, StyledPanel }
