import styled, { css } from "styled-components"

const StyledWrapper = styled.div`
  display: none;

  ${({ theme }) => theme.mq.medium} {
    display: flex;
    margin-left: 13rem;
    justify-content: space-between;
    width: 100%;
  }
`

const StyledNav = styled.nav`
  display: flex;
  align-items: center;
`

const StyledList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
`

const StyledButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`

const logInLinkStyles = css`
  margin-right: 1.6rem;
  color: inherit;

  & > button {
    color: inherit;
  }
`

export {
  StyledList,
  StyledNav,
  StyledWrapper,
  StyledButtonWrapper,
  logInLinkStyles,
}
