import React, { Fragment, useCallback, useEffect, useState } from "react"
import { useIntl } from "gatsby-plugin-intl"
import { useClipboard } from "use-clipboard-copy"
import browserWindow from "utils/browserWindow"
import trackingCategoriesHelper from "utils/trackingCategoriesHelper"

import paths from "utils/paths"

import Logo from "components/atoms/Logo"
import Tooltip from "components/atoms/Tooltip"
import LanguageSelect from "components/molecules/LanguageSelect"
import BtnLink from "components/atoms/BtnLink"

import Location from "assets/icons/location.svg"
import Phone from "assets/icons/phone.svg"
import Send from "assets/icons/send.svg"
import {
  StyledFooter,
  StyledSectionWrapper,
  StyledLogoWrapper,
  StyledLink,
  StyledContactButton,
  StyledFooterBottom,
  StyledCopyrightAndLanguage,
  StyledFooterBottomList,
  StyledVSeparator,
  StyledIconWrapper,
  contactButtonStyles,
} from "./Footer.styles"
import FooterColumn from "./FooterColumn"

import { StyledList } from "./FooterColumn/FooterColumn.styles"

const Footer = () => {
  const { formatMessage } = useIntl()
  const clipboard = useClipboard()
  const [footerCopyLinks, setFooterCopyLinks] = useState([])

  const {
    home,
    contact,
    blog,
    portfolio,
    privacyPolicy,
    sitemap,
    termsOfService,
    getStarted,
    about,
    aboutWhatWeDo,
    aboutSeeOurImpact,
    aboutSurveyOurProcess,
    whyExdev,
    frontrowReporting,
    directToDeveloper,
    seamlessExperience,
    exdevDifference,
    howItWorks,
    popularQuestions,
    investInCRE,
  } = paths

  useEffect(() => {
    setFooterCopyLinks([
      {
        path: browserWindow.location && browserWindow.location.origin + sitemap,
        label: formatMessage({
          id: "footer.copy.sitemap",
        }),
      },
      {
        path: termsOfService,
        label: formatMessage({
          id: "footer.copy.terms",
        }),
      },
      {
        path: privacyPolicy,
        label: formatMessage({
          id: "footer.copy.privacy",
        }),
      },
    ])
  }, [])

  const copyToClipboard = useCallback(
    (clipboardContent) => {
      clipboard.copy(clipboardContent)
    },
    [clipboard.copy]
  )

  const iconType = {
    location: <Location />,
    phone: <Phone />,
    send: <Send />,
  }

  const footerContact = [
    {
      icon: "send",
      label: "info@exdev.com",
    },
    {
      icon: "phone",
      label: "+1.626.384.5050",
    },
    {
      icon: "location",
      label: "17777 Center Court Dr. N #725 Cerritos, CA 90703",
    },
  ]

  const footerContent = [
    {
      mainLabel: {
        label: formatMessage({ id: "footer.about.label" }),
      },
      links: [
        {
          path: about,
          label: formatMessage({
            id: "footer.about.links.introduction",
          }),
        },
        {
          path: aboutWhatWeDo,
          label: formatMessage({
            id: "footer.about.links.whatWeDo",
          }),
        },
        {
          path: aboutSeeOurImpact,
          label: formatMessage({
            id: "footer.about.links.seeOurImpact",
          }),
        },
        {
          path: aboutSurveyOurProcess,
          label: formatMessage({
            id: "footer.about.links.surveyOurProcess",
          }),
        },
        {
          path: blog,
          label: formatMessage({
            id: "footer.about.links.blog",
          }),
        },
      ],
    },
    {
      mainLabel: {
        label: formatMessage({ id: "footer.invest.label" }),
      },
      links: [
        {
          path: investInCRE,
          label: formatMessage({
            id: "footer.invest.links.introduction",
          }),
        },
        {
          path: whyExdev,
          label: formatMessage({
            id: "footer.invest.links.whyExdev",
          }),
        },
        {
          path: frontrowReporting,
          label: formatMessage({
            id: "footer.invest.links.frontrowReporting",
          }),
        },
        {
          path: directToDeveloper,
          label: formatMessage({
            id: "footer.invest.links.directToDeveloper",
          }),
        },
        {
          path: seamlessExperience,
          label: formatMessage({
            id: "footer.invest.links.seamlessExperience",
          }),
        },
        {
          path: exdevDifference,
          label: formatMessage({
            id: "footer.invest.links.exdevDifference",
          }),
        },
        {
          path: howItWorks,
          label: formatMessage({
            id: "footer.invest.links.howItWorks",
          }),
        },
        {
          path: popularQuestions,
          label: formatMessage({
            id: "footer.invest.links.popularQuestions",
          }),
        },
        {
          path: getStarted,
          label: formatMessage({
            id: "footer.invest.links.register",
          }),
        },
      ],
    },
    {
      mainLabel: {
        label: formatMessage({ id: "footer.portfolio.label" }),
      },
      links: [
        {
          path: portfolio,
          label: formatMessage({
            id: "footer.portfolio.links.groundUp",
          }),
          state: {
            selectedProjectType: "GROUND_UP",
            selectedPropertyType: "ALL_TYPES",
          },
        },
        {
          path: portfolio,
          label: formatMessage({
            id: "footer.portfolio.links.mixedUse",
          }),
          state: {
            selectedProjectType: "ALL_TYPES",
            selectedPropertyType: "MIXED_USE",
          },
        },
        {
          path: portfolio,
          label: formatMessage({
            id: "footer.portfolio.links.hotel",
          }),
          state: {
            selectedProjectType: "ALL_TYPES",
            selectedPropertyType: "HOTEL",
          },
        },
        {
          path: portfolio,
          label: formatMessage({
            id: "footer.portfolio.links.office",
          }),
          state: {
            selectedProjectType: "ALL_TYPES",
            selectedPropertyType: "OFFICE_MEDICAL",
          },
        },
        {
          path: portfolio,
          label: formatMessage({
            id: "footer.portfolio.links.industrialFlex",
          }),
          state: {
            selectedProjectType: "ALL_TYPES",
            selectedPropertyType: "INDUSTRIAL_FLEX",
          },
        },
      ],
    },
  ]

  return (
    <StyledFooter data-testid="footer">
      <StyledSectionWrapper>
        <StyledList>
          <StyledLogoWrapper>
            <StyledLink aria-label="home page link" to={home}>
              <Logo />
            </StyledLink>
          </StyledLogoWrapper>
          {footerContact.map((contactItem) => (
            <Fragment key={`${contactItem.label}_contact`}>
              <StyledContactButton
                type="button"
                data-tip
                data-for={`${contactItem.label}_contact`}
                data-event="click"
              >
                <StyledIconWrapper>
                  {iconType[contactItem.icon]}
                </StyledIconWrapper>
                <span>{contactItem.label}</span>
              </StyledContactButton>
              <Tooltip
                id={`${contactItem.label}_contact`}
                place="right"
                effect="solid"
                type="light"
                clickable
                customAfterShow={() => {
                  copyToClipboard(contactItem.label)
                }}
              >
                {formatMessage({
                  id: "copied",
                })}
              </Tooltip>
            </Fragment>
          ))}
        </StyledList>
        {footerContent.map((el) => (
          <Fragment key={`${el.mainLabel.label}_label`}>
            <FooterColumn columnProps={el} />
          </Fragment>
        ))}
      </StyledSectionWrapper>
      <StyledSectionWrapper>
        <StyledFooterBottom>
          <StyledCopyrightAndLanguage>
            <StyledVSeparator />
            <BtnLink
              to={contact}
              extraStyles={contactButtonStyles}
              trackingOptions={{
                category: trackingCategoriesHelper.FOOTER,
                action: "Click",
                label: `${trackingCategoriesHelper.FOOTER} link(Contact)`,
              }}
            >
              {formatMessage({
                id: "contact",
              })}
            </BtnLink>
            <LanguageSelect testId="footerLanguageSelect" />
            <span>&#169; exdev</span>
          </StyledCopyrightAndLanguage>
          <StyledFooterBottomList>
            {footerCopyLinks.map((link) => (
              <li key={`${link.label}_contact`}>
                <BtnLink
                  to={link.path}
                  trackingOptions={{
                    category: trackingCategoriesHelper.FOOTER,
                    action: "Click",
                    label: `${trackingCategoriesHelper.FOOTER} link(${link.label})`,
                  }}
                >
                  {link.label}
                </BtnLink>
              </li>
            ))}
          </StyledFooterBottomList>
        </StyledFooterBottom>
      </StyledSectionWrapper>
    </StyledFooter>
  )
}

export default Footer
