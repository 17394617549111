import styled, { css } from "styled-components"

const linkStyles = css`
  text-decoration: none;
  display: block;
  font-family: ${({ theme }) => theme.font.family.body};
  font-size: ${({ theme }) => theme.font.size.s};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  line-height: 1.71;
  color: inherit;
  padding: 2.4rem;
`

const StyledNavItem = styled.li`
  position: relative;

  &:hover {
    background-color: ${({ theme }) => theme.color.secondary05};

    .subMenuContent {
      display: block;
    }
  }
`

export { StyledNavItem, linkStyles }
