import React from "react"
import PropTypes from "prop-types"

import styled from "styled-components"
import LogoExdev from "assets/logos/exdev.svg"

const StyledLogoExdev = styled(LogoExdev)`
  height: 2rem;
  width: 5.8rem;
  display: inline-block;

  * {
    fill: ${({ theme }) => theme.color.white};
  }

  ${({ extrastyles }) => extrastyles};
`

const Logo = ({ extrastyles, ...props }) => (
  <StyledLogoExdev extrastyles={extrastyles} {...props} />
)

Logo.propTypes = {
  extrastyles: PropTypes.any,
}

Logo.defaultProps = {
  extrastyles: "",
}
export default Logo
