import styled, { css } from "styled-components"
import Banner from "components/atoms/Banner"
import Button from "components/atoms/Button"

const paragraphExtraStyles = css`
  color: ${({ theme }) => theme.color.white};
  margin-bottom: 1.2rem;

  ${({ theme }) => theme.mq.medium} {
    margin: 0 2.2rem 0 1.6rem;
  }
`
const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.color.white};

  &:hover {
    color: ${({ theme }) => theme.color.secondary01};
  }
`
const StyledBanner = styled(Banner)`
  background-color: ${({ theme }) => theme.color.quinary02};
`

export { paragraphExtraStyles, StyledBanner, StyledButton }
