const apiLocale = {
  en: "en",
  ko: "ko",
  vi: "vi",
  "zh-Hant": `zh_tw`,
  "zh-Hans": `zh_cn`,
}

const getGlobalAlert = async (language) => {
  const response = await fetch(process.env.GATSBY_API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic  ${process.env.GATSBY_API_BASE_AUTH}`,
    },
    body: JSON.stringify({
      query: `   query {
        globalAlertBanner(language: ${apiLocale[language]}) {
            buttonDestination
            buttonText
            isPublished
            message
          }
        }
        `,
      variables: null,
    }),
  })
  const body = await response.json()
  return body
}

export default getGlobalAlert
