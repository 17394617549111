import React from "react"
import PropTypes from "prop-types"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"
import Select from "components/atoms/Select"

const langList = [
  { label: "English", value: "en" },
  { label: "Chinese(Traditional)", value: "zh-Hant" },
  { label: "Chinese(Simplified)", value: "zh-Hans" },
  { label: "Korean", value: "ko" },
  { label: "Vietnamese", value: "vi" },
]

const languagesHomePagePaths = langList.map((lang) => `/${lang.value}`)

const handleLocaleChange = (lang) => {
  const pathnameHasNoTrailingSlash = languagesHomePagePaths.includes(
    window.location.pathname
  )

  changeLocale(lang.value, pathnameHasNoTrailingSlash ? "/" : "")
}

const LanguageSelect = ({ testId }) => {
  return (
    <IntlContextConsumer>
      {({ language: currentLocale }) => (
        <Select
          items={langList}
          handleOnChange={handleLocaleChange}
          currentValue={langList.find((lang) => lang.value === currentLocale)}
          testId={testId}
        />
      )}
    </IntlContextConsumer>
  )
}

LanguageSelect.propTypes = {
  testId: PropTypes.string.isRequired,
}

export default LanguageSelect
