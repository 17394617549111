import browserWindow from "utils/browserWindow"

export const languageBannerContent = [
  {
    name: "Chinese(Simplified)",
    languageCode: "zh-CN",
    value: "zh-Hans",
    text: "您想用中文浏览该网站吗?",
    acceptButton: "好",
    cancelButton: "不好",
  },
  {
    name: "Chinese(Traditional)",
    languageCode: "zh-TW",
    value: "zh-Hant",
    text: "您想用中文瀏覽該網站嗎?",
    acceptButton: "好",
    cancelButton: "不好",
  },
  {
    name: "Korean",
    languageCode: "ko",
    value: "ko",
    text: "이 웹사이트를 한국어로 보시겠습니까?",
    acceptButton: "네",
    cancelButton: "아니요",
  },
  {
    name: "Vietnamese",
    languageCode: "vi",
    value: "vi",
    text: "Bạn có muốn xem trang web này bằng tiếng Việt?",
    acceptButton: "Có",
    cancelButton: "Không",
  },
]

const browserLanguageList =
  browserWindow.navigator && browserWindow.navigator.languages

const getPossibleLanguage =
  browserLanguageList &&
  browserLanguageList
    .map((browserLang) =>
      languageBannerContent.find((lang) => lang.languageCode === browserLang)
    )
    .filter(Boolean)

const languageBannerHelper = (language = "") => {
  if (language === "") return null
  if (
    getPossibleLanguage[0] &&
    getPossibleLanguage[0].languageCode === language
  )
    return null

  const filterCurrentLanguage = getPossibleLanguage.filter(
    (lang) => lang.languageCode !== language
  )

  return filterCurrentLanguage[0] || null
}

export default languageBannerHelper
