import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"

import ProgressStagesLabel from "components/content/Portfolio/ProjectProgress/ProgressStagesLabel"
import { StyledWrapper, StyledPanel } from "./Accordion.styles"
import AccordionButton from "./AccordionButton"

const Accordion = ({
  children,
  buttonText,
  btnStyles,
  accordionStyles,
  progressMilestones,
}) => {
  const [isOpen, togglePanel] = useState(false)
  const [panelHeight, setPanelHeight] = useState(0)
  const [panelDefaultHeight, setPanelDefaultHeight] = useState(0)

  const panelRef = useRef(null)

  useEffect(() => {
    setPanelDefaultHeight(panelRef.current.scrollHeight)
  }, [panelRef])

  useEffect(() => {
    if (isOpen) {
      setPanelHeight(panelDefaultHeight)
    } else {
      setPanelHeight(0)
    }
  }, [isOpen])

  const handleTogglePanel = () => {
    setPanelDefaultHeight(panelRef.current.scrollHeight)
    togglePanel(!isOpen)
  }

  return (
    <StyledWrapper isOpen={isOpen} accordionStyles={accordionStyles}>
      <AccordionButton
        btnStyles={btnStyles}
        handleTogglePanel={handleTogglePanel}
        isOpen={isOpen}
      >
        {progressMilestones && (
          <ProgressStagesLabel progressMilestones={progressMilestones} />
        )}
        {buttonText}
      </AccordionButton>
      <StyledPanel ref={panelRef} style={{ maxHeight: `${panelHeight}px` }}>
        {children}
      </StyledPanel>
    </StyledWrapper>
  )
}

Accordion.propTypes = {
  buttonText: PropTypes.string.isRequired,
  btnStyles: PropTypes.any,
  accordionStyles: PropTypes.any,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  progressMilestones: PropTypes.shape({
    completedNumberOfMilestones: PropTypes.number,
    totalNumberOfMilestones: PropTypes.number,
    status: PropTypes.string,
  }),
}

Accordion.defaultProps = {
  btnStyles: "",
  accordionStyles: "",
  progressMilestones: null,
}

export default Accordion
