import styled from "styled-components"

const StyledSelectWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: max-content;
  height: max-content;
`

const StyledSelectButton = styled.button`
  color: ${({ theme }) => theme.color.secondary03};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  padding: 0;
  border: none;
  background: transparent;
  display: flex;
  align-items: center;

  ${({ extraInputStyles }) => extraInputStyles};
`

export { StyledSelectWrapper, StyledSelectButton }
