import React from "react"
import PropTypes from "prop-types"

import Check from "assets/icons/check.svg"
import InProgress from "assets/icons/in-progress.svg"
import Pending from "assets/icons/pending.svg"

import { StyledStagesLabel } from "./ProgressStagesLabel.styles"

const getStagesStatusIcon = (status) => {
  const stageStatusIconComponents = {
    COMPLETE: <Check />,
    IN_PROGRESS: <InProgress />,
    PENDING: <Pending />,
  }
  return stageStatusIconComponents[status] || []
}

const ProgressStagesLabel = ({ progressMilestones }) => {
  const {
    status,
    completedNumberOfMilestones,
    totalNumberOfMilestones,
  } = progressMilestones

  return (
    <StyledStagesLabel status={status}>
      {getStagesStatusIcon(status)}
      {`${completedNumberOfMilestones}/${totalNumberOfMilestones}`}
    </StyledStagesLabel>
  )
}

ProgressStagesLabel.propTypes = {
  progressMilestones: PropTypes.shape({
    completedNumberOfMilestones: PropTypes.number,
    totalNumberOfMilestones: PropTypes.number,
    status: PropTypes.string,
  }).isRequired,
}

export default ProgressStagesLabel
