import styled, { css } from "styled-components"
import { Link } from "gatsby-plugin-intl"

const afterActionStyles = css`
  background-color: ${({ theme }) => theme.color.white};
  border-bottom: 1px solid ${({ theme }) => theme.color.secondary04};
  color: ${({ theme }) => theme.color.secondary01};
  fill: ${({ theme }) => theme.color.secondary01};
`

const landscapeScrolled = css`
  @media screen and (orientation: landscape) {
    ${afterActionStyles}
  }
`
const portraitScrolled = css`
  @media screen and (orientation: portrait) {
    ${afterActionStyles}
  }
`
const desktopScrolled = css`
  ${({ theme }) => theme.mq.medium} {
    ${afterActionStyles}
  }
`

const StyledHeader = styled.header`
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  border-bottom: 1px solid transparent;
  color: ${({ theme }) => theme.color.secondary01};
  transition: background-color 0.3s linear, border 0.3s linear,
  color 0.3s linear, fill 0.3s linear;

  @media (hover: hover) {
    &:hover {
      ${afterActionStyles}
    }
  }

    ${({ isNavStatic }) =>
      isNavStatic &&
      css`
        position: relative;
        border-bottom: 1px solid ${({ theme }) => theme.color.secondary04};

        ${({ theme }) => theme.mq.medium} {
          position: fixed;
          border-bottom: 1px solid transparent;
        }
      `}

    ${({ isReverseColor }) =>
      isReverseColor &&
      css`
        color: ${({ theme }) => theme.color.white};
        fill: ${({ theme }) => theme.color.white};
      `}

      ${({ isOpened }) =>
        isOpened &&
        css`
          ${afterActionStyles}
          top: 0;
        `}

    ${({ isScrolled, blockScrollAnimationOn }) =>
      isScrolled &&
      css`
        ${!blockScrollAnimationOn.mobileLandscape && landscapeScrolled}
        ${!blockScrollAnimationOn.mobilePortrait && portraitScrolled}
        ${!blockScrollAnimationOn.desktop && desktopScrolled}

      `}

        ${({ bannerHeight }) =>
          css`
            top: ${bannerHeight}px;
          `}

  ${({ theme }) => theme.mq.medium} {
    padding: 0 ;
  }
`

const StyledLink = styled(Link)`
  height: 2rem;
`

const logoStyles = css`
  height: 1.7rem;
  * {
    fill: inherit;
  }
`

const StyledWrapper = styled.div`
  max-width: ${({ theme }) => theme.gridWidth};
  height: 7.2rem;
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 ${({ theme }) => theme.layout.mobilePadding};
`
export { StyledHeader, StyledLink, logoStyles, StyledWrapper }
