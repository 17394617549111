import styled, { css } from "styled-components"
import { Link } from "gatsby-plugin-intl"

const StyledWrapper = styled.div`
  position: fixed;
  background: white;
  z-index: 90;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  transition: 0.3s ease-in-out;
  transform: translateY(-100%);
  padding: 7.2rem 0 4rem;
  ${({ isExpanded }) =>
    isExpanded &&
    css`
      transform: translateY(0);
    `}
`

const StyledNav = styled.nav`
  display: flex;
  flex-direction: column;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  display: block;
  font-family: ${({ theme }) => theme.font.family.body};
  font-size: ${({ theme }) => theme.font.size.s};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  line-height: 1.71;
  color: ${({ theme }) => theme.color.secondary01};
`

const StyledButtonLink = styled(Link)`
  width: 100%;
  outline: none;
  background: ${({ theme }) => theme.color.white};
  font-family: ${({ theme }) => theme.font.family.body};
  position: relative;
  cursor: pointer;
  line-height: 1.71;
  border: 0;
  font-size: ${({ theme }) => theme.font.size.s};
  text-align: left;
  color: ${({ theme }) => theme.color.secondary01};
  padding: 1.6rem;
  border-bottom: 1px solid ${({ theme }) => theme.color.secondary04};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
`

const StyledLinksWrapper = styled.div`
  flex-direction: column;
  padding: 1.6rem;
  display: flex;
`
const StyledTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1.6rem;
`

const StyledIconWrapper = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  flex-shrink: 0;

  > svg {
    width: 100%;
    height: 100%;
    fill: ${({ theme }) => theme.color.secondary03};
  }
`

const StyledMenuItemWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0.8rem 0;
`
const StyledIcon = styled.i`
  position: absolute;
  right: 2.5rem;
  top: 40%;
  transform: rotate(-45deg);
  border: solid ${({ theme }) => theme.color.secondary02};
  border-width: 0 1px 1px 0;
  padding: 0.5rem;
`
const StyledButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1.6rem;
  margin-top: 4.8rem;
`
const navButtonStyles = css`
  height: 4rem;
  padding: 0.8rem 2.4rem;
  font-family: ${({ theme }) => theme.font.family.body};
  font-size: ${({ theme }) => theme.font.size.s};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  line-height: 1.71;
  cursor: pointer;
`

const registerLinkStyles = css`
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.tertiary03};
  border: 2px solid ${({ theme }) => theme.color.tertiary03};

  ${navButtonStyles};
`
const logInLinkStyles = css`
  margin-right: 1.6rem;
  color: ${({ theme }) => theme.color.secondary01};
  border: 2px solid ${({ theme }) => theme.color.secondary04};

  ${navButtonStyles}
`
export {
  StyledWrapper,
  StyledNav,
  StyledLink,
  StyledLinksWrapper,
  StyledTextWrapper,
  StyledIconWrapper,
  StyledMenuItemWrapper,
  StyledButtonLink,
  StyledIcon,
  StyledButtonWrapper,
  registerLinkStyles,
  logInLinkStyles,
}
