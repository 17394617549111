import styled, { css } from "styled-components"

const StyledButton = styled.button`
  width: 100%;
  outline: none;
  background: ${({ theme }) => theme.color.white};
  font-family: ${({ theme }) => theme.font.family.body};
  position: relative;
  cursor: pointer;
  line-height: 1.71;
  border: 0;
  font-size: ${({ theme }) => theme.font.size.s};
  text-align: left;
  color: ${({ theme }) => theme.color.secondary01};
  padding: 1.6rem;
  font-weight: ${({ theme }) => theme.font.weight.semiBold};

  ${({ btnStyles }) => btnStyles}
`

const StyledIcon = styled.i`
  position: absolute;
  right: 2rem;
  top: 40%;
  transform: rotate(45deg);
  border: solid ${({ theme }) => theme.color.secondary02};
  border-width: 0 1px 1px 0;
  padding: 0.5rem;
  transition: transform 0.5s ease-out;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(-135deg);
    `}
`

export { StyledButton, StyledIcon }
