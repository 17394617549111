import React from "react"
import PropTypes from "prop-types"
import { useIntl } from "gatsby-plugin-intl"

import useNavigationContent from "utils/useNavigationContent"
import paths from "utils/paths"

import Icon from "components/atoms/Icon"
import Accordion from "components/atoms/Accordion"
import P from "components/atoms/P"
import Button from "components/atoms/Button"
import {
  StyledWrapper,
  StyledNav,
  StyledLink,
  StyledLinksWrapper,
  StyledTextWrapper,
  StyledIconWrapper,
  StyledMenuItemWrapper,
  StyledButtonLink,
  StyledIcon,
  StyledButtonWrapper,
  logInLinkStyles,
} from "./MobileMenu.styles"

const MobileMenu = ({ isExpanded, toggleMenu }) => {
  const { formatMessage } = useIntl()
  const { contact, getStarted } = paths

  return (
    <StyledWrapper isExpanded={isExpanded}>
      <StyledNav>
        {useNavigationContent().map((el) => {
          if (el.subMenuContent) {
            const { subMenuContent } = el

            return (
              <Accordion buttonText={el.label} key={el.label}>
                <StyledLinksWrapper>
                  {subMenuContent.map((item) => (
                    <StyledMenuItemWrapper key={item.label}>
                      <StyledIconWrapper>
                        {item.icon && <Icon icon={`${item.icon}`} />}
                      </StyledIconWrapper>
                      <StyledTextWrapper>
                        <StyledLink
                          type="subMenu"
                          onClick={() => toggleMenu()}
                          to={item.path}
                        >
                          {item.label}
                        </StyledLink>
                        <P>{item.text}</P>
                      </StyledTextWrapper>
                    </StyledMenuItemWrapper>
                  ))}
                </StyledLinksWrapper>
              </Accordion>
            )
          }
          return (
            <StyledButtonLink
              key={el.label}
              to={el.path}
              onClick={() => toggleMenu()}
            >
              {el.label}
              <StyledIcon />
            </StyledButtonLink>
          )
        })}
        <StyledButtonWrapper>
          <Button
            size="medium"
            type="secondary"
            extraStyles={logInLinkStyles}
            to={contact}
          >
            {formatMessage({ id: "navbar.contact" })}
          </Button>
          <Button size="medium" to={getStarted}>
            {formatMessage({ id: "navbar.getStarted" })}
          </Button>
        </StyledButtonWrapper>
      </StyledNav>
    </StyledWrapper>
  )
}

MobileMenu.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
}

export default MobileMenu
