import styled, { css } from "styled-components"
import Banner from "components/atoms/Banner"

const paragraphExtraStyles = css`
  color: ${({ theme }) => theme.color.white};
  margin-right: 2.2rem;
`

const StyledButtonWrapper = styled.div`
  display: flex;
  margin-top: 1.6rem;

  & > :first-child {
    margin-right: 0.8rem;
  }

  ${({ theme }) => theme.mq.medium} {
    margin-top: 0;
  }
`
const StyledBanner = styled(Banner)`
  background-color: ${({ theme }) => theme.color.secondary02};
`
export { paragraphExtraStyles, StyledButtonWrapper, StyledBanner }
