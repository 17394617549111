import styled, { css } from "styled-components"
import { Link } from "gatsby-plugin-intl"

const StyledFooter = styled.footer`
  height: auto;
  width: 100%;
  background: ${({ theme }) => theme.color.main01};
  padding: 4.8rem 0;

  ${({ theme }) => theme.mq.medium} {
    padding: 4.8rem 0 0;
  }
`

const StyledLogoWrapper = styled.div`
  margin: 0 0 2rem;
  width: max-content;
`

const StyledLink = styled(Link)`
  line-height: 1.6rem;
  align-self: baseline;
  text-decoration: none;
  margin: 0.8rem 0;
  display: block;
`

const StyledSectionWrapper = styled.div`
  max-width: ${({ theme }) => theme.gridWidth};
  padding: 0 1.6rem;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${({ theme }) => theme.mq.medium} {
    flex-wrap: nowrap;
  }
`

const StyledFooterBottom = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-top: 0.1rem solid ${({ theme }) => theme.color.secondary02};
  padding: 2.4rem 0 0;
  min-height: 6.8rem;

  ${({ theme }) => theme.mq.medium} {
    margin: 2.8rem 0 0;
  }
`

const StyledContactButton = styled.button`
  color: ${({ theme }) => theme.color.secondary03};
  border: none;
  background: transparent;
  line-height: 1.6rem;
  align-items: center;
  text-decoration: none;
  margin: 0.8rem 0;
  padding: 0;
  display: flex;
  cursor: pointer;
  width: max-content;

  span {
    max-width: 22rem;
  }
`

const StyledCopyrightAndLanguage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(50% - 1.6rem);

  color: ${({ theme }) => theme.color.secondary03};
  font-size: ${({ theme }) => theme.font.size.xs};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  line-height: 2rem;
  text-decoration: none;
  list-style: none;
  margin: 0;
  padding: 0;

  * {
    font-size: inherit;
  }

  ${({ theme }) => theme.mq.medium} {
    flex-direction: row;
    width: auto;

    & > :last-child {
      order: -1;
    }
  }
`

const StyledFooterBottomList = styled.ul`
  display: flex;
  flex-direction: column;
  width: calc(50% - 1.6rem);

  color: ${({ theme }) => theme.color.secondary03};
  font-size: ${({ theme }) => theme.font.size.xs};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  line-height: 2rem;
  text-decoration: none;
  list-style: none;
  padding: 0;
  margin: 0;

  * {
    font-size: inherit;
    color: inherit;
    margin: 0;
  }

  > li {
    margin: 0.8rem 0;

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  ${({ theme }) => theme.mq.medium} {
    flex-direction: row;
    width: auto;

    > li {
      margin: 0 4rem 0 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }
`

const StyledIconWrapper = styled.div`
  position: relative;
  margin-top: 0.4rem;
  margin-right: 1.6rem;
  align-self: baseline;

  & > svg {
    height: 1.6rem;
    width: auto;

    * {
      fill: ${({ theme }) => theme.color.secondary03};
    }
  }
`

const StyledVSeparator = styled.div`
  display: none;
  width: 0.1rem;
  height: 1.6rem;
  margin: 0 4rem;
  background: ${({ theme }) => theme.color.secondary02};

  ${({ theme }) => theme.mq.medium} {
    display: inline-block;
  }
`

const contactButtonStyles = css`
  color: ${({ theme }) => theme.color.secondary03};
  font-size: ${({ theme }) => theme.font.size.xs};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  margin-right: 3.2rem;
`

export {
  StyledFooter,
  StyledSectionWrapper,
  StyledLogoWrapper,
  StyledLink,
  StyledContactButton,
  StyledFooterBottom,
  StyledCopyrightAndLanguage,
  StyledFooterBottomList,
  StyledVSeparator,
  StyledIconWrapper,
  contactButtonStyles,
}
