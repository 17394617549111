import React from "react"
import { useIntl } from "gatsby-plugin-intl"

import trackingCategoriesHelper from "utils/trackingCategoriesHelper"
import useNavigationContent from "utils/useNavigationContent"
import paths from "utils/paths"
import BtnLink from "components/atoms/BtnLink"
import Button from "components/atoms/Button"
import {
  StyledList,
  StyledNav,
  StyledWrapper,
  StyledButtonWrapper,
  logInLinkStyles,
} from "./DesktopNav.styles"
import LinkItem from "../LinkItem"

const DesktopNav = () => {
  const { formatMessage } = useIntl()
  const { contact, getStarted } = paths

  return (
    <StyledWrapper>
      <StyledNav>
        <StyledList>
          {useNavigationContent().map((el) => (
            <LinkItem key={el.label} linkProps={el} />
          ))}
        </StyledList>
      </StyledNav>
      <StyledButtonWrapper>
        <BtnLink linkStyles={logInLinkStyles} to={contact}>
          <Button
            size="medium"
            type="secondary"
            trackingOptions={{
              category: trackingCategoriesHelper.GLOBAL_NAVIGATION,
              action: "Click",
              label: `${trackingCategoriesHelper.GLOBAL_NAVIGATION} link(contact)`,
            }}
          >
            {formatMessage({ id: "navbar.contact" })}
          </Button>
        </BtnLink>
        <BtnLink to={getStarted}>
          <Button
            size="medium"
            trackingOptions={{
              category: trackingCategoriesHelper.GLOBAL_NAVIGATION,
              action: "Click",
              label: `${trackingCategoriesHelper.GLOBAL_NAVIGATION} link(getStarted)`,
            }}
          >
            {formatMessage({ id: "navbar.getStarted" })}
          </Button>
        </BtnLink>
      </StyledButtonWrapper>
    </StyledWrapper>
  )
}

export default DesktopNav
