import styled, { css } from "styled-components"

const StyledSubMenuWrapper = styled.div`
  position: absolute;
  top: calc(100% - 0.5rem);
  width: 44rem;
  left: 50%;
  transform: translateX(-50%);
  display: none;
  background: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.secondary04};

  ::before {
    display: block;
    content: "";
    background: ${({ theme }) => theme.color.white};
    height: 1.6rem;
    width: 1.6rem;

    position: absolute;

    left: 48%;

    top: -0.9rem;
    transform: rotate(45deg);

    border-left: 1px solid ${({ theme }) => theme.color.secondary04};
    border-top: 1px solid ${({ theme }) => theme.color.secondary04};
  }
`

const StyledIconWrapper = styled.div`
  max-width: 26.8rem;
  width: 100%;
  height: auto;
  max-height: 12rem;

  > svg {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    transition: transform 0.3s linear;
  }
`

const StyledList = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mq.medium} {
    margin: 0 auto;
    max-width: ${({ theme }) => theme.gridWidth};
    padding: 3.2rem;
  }
`

const StyledListItem = styled.li`
  display: flex;
  width: 100%;

  margin-bottom: 1.6rem;

  svg {
    fill: ${({ theme }) => theme.color.secondary03};
    transition: fill 0.2s linear;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  :hover {
    svg {
      fill: ${({ theme }) => theme.color.septenary03};
    }
  }
`

const StyledHeader = styled.p`
  font-family: ${({ theme }) => theme.font.family.body};
  font-size: ${({ theme }) => theme.font.size.md};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  line-height: 1.75;
  letter-spacing: normal;
  color: ${({ theme }) => theme.color.secondary01};
`

const StyledImgWrapper = styled.div`
  display: flex;
  margin-right: 1.6rem;
`

const menuButtonStyles = css`
  display: flex;
  align-items: center;
  padding: 1rem 2.4rem;
  transition: background-color 0.2s linear;

  width: 100%;

  :hover {
    background-color: ${({ theme }) => theme.color.secondary05};
  }
`

export {
  StyledSubMenuWrapper,
  StyledList,
  StyledListItem,
  StyledHeader,
  StyledImgWrapper,
  StyledIconWrapper,
  menuButtonStyles,
}
