import styled, { css } from "styled-components"

const StyledHamburger = styled.button`
  outline: none;
  border: none;
  background: none;
  width: 2rem;
  height: 1.4rem;
  margin-left: auto;
  cursor: pointer;
  position: relative;

  ${({ theme }) => theme.mq.medium} {
    display: none;
  }
`

const StyledLine = styled.span`
  width: 100%;
  height: 2px;
  transition: 0.3s ease-in-out;
  position: absolute;
  right: 0;
  transform: translateY(-50%);
  border-radius: 2px;
  background-color: ${({ theme }) => theme.color.secondary02};

  top: ${({ top }) => top};

  ${({ isReverseColor }) =>
    isReverseColor &&
    css`
      background-color: ${({ theme }) => theme.color.white};
    `};

  ${({ openStyles }) =>
    openStyles &&
    css`
      ${openStyles}
      background-color: ${({ theme }) => theme.color.secondary02};
    `};
`

export { StyledHamburger, StyledLine }
