import React from "react"
import PropTypes from "prop-types"
import Icons from "utils/icons"

const Components = { ...Icons }

const Icon = ({ icon, className, onClick }) => {
  if (typeof Components[icon] !== "undefined") {
    const IconComponent = React.createElement(Components[icon], {
      key: icon,
      className,
      onClick,
    })

    return <>{IconComponent}</>
  }
  // component doesn't exist yet
  return React.createElement(
    () => <div>The component {icon} has not been created yet.</div>,
    { key: icon }
  )
}

Icon.propTypes = {
  icon: PropTypes.oneOfType([
    PropTypes.shape({
      original: PropTypes.string,
      large: PropTypes.string,
      medium: PropTypes.string,
      small: PropTypes.string,
    }),
    PropTypes.string,
  ]).isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
}
Icon.defaultProps = {
  className: "",
  onClick: () => null,
}
export default Icon
