import React from "react"
import PropTypes from "prop-types"

import { StyledHamburger, StyledLine } from "./Hamburger.styles"

const Hamburger = ({ isOpen, toggleMenu, isReverseColor }) => {
  const renderLines = () => {
    const lines = [
      {
        index: 1,
        top: "0%",
        openStyles: `${
          isOpen ? "transform: translateY(25px); opacity: 0;" : ""
        }`,
      },
      {
        index: 2,
        top: "50%",
        openStyles: `${
          isOpen ? "transform: translateY(-50%) rotate(45deg);" : ""
        }`,
      },
      {
        index: 3,
        top: "50%",
        openStyles: `${
          isOpen ? "transform: translateY(-50%) rotate(-45deg);" : ""
        }`,
      },
      {
        index: 4,
        top: "100%",
        openStyles: `${
          isOpen ? "transform: translateY(-25px); opacity: 0;" : ""
        }`,
      },
    ]

    return lines.map((el) => (
      <StyledLine
        key={el.index}
        top={el.top}
        openStyles={el.openStyles}
        isReverseColor={isReverseColor}
      />
    ))
  }

  return (
    <StyledHamburger aria-label="hamburger" onClick={toggleMenu}>
      {renderLines()}
    </StyledHamburger>
  )
}

Hamburger.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  isReverseColor: PropTypes.bool.isRequired,
}

export default Hamburger
