export default {
  GLOBAL_NAVIGATION: "Global navigation",
  FOOTER: "Footer",
  HOME: "Home",
  ABOUT: "About",
  UNDERSTAND_OUR_BUSINESS: "Understand our business",
  SURVEY_OUR_PROCESS: "Survey our process",
  BLOG: "Blog",
  BLOG_ARTICLE: "Blog article",
  WHY_EXDEV: "Why Exdev",
  HOW_IT_WORKS: "How it works",
  PORTFOLIO_FILTER: "Portfolio filter",
  PORTFOLIO: "Portfolio",
  PORTFOLIO_MAP: "Portfolio Map",
  PROJECT_NAVIGATION: "Project navigation",
  PROJECT_SNAPSHOT: "Project snapshot",
  PROJECT_VISION: "Project vision",
  PROJECT_LOCATION: "Project location",
  PROJECT_BRIEFING: "Project briefing",
  PROJECT_PROGRESS: "Project progress",
  PROJECT_TIMELINE: "Project timeline",
  PROJECT_GALLERY: "Project gallery",
  SUBSCRIBE: "Subscribe",
  CONTACT: "Contact",
}
