import { useIntl } from "gatsby-plugin-intl"

import paths from "utils/paths"

const useNavigationContent = () => {
  const { formatMessage } = useIntl()

  const {
    about,
    aboutWhatWeDo,
    aboutSurveyOurProcess,
    aboutSeeOurImpact,
    blog,
    investInCRE,
    whyExdev,
    frontrowReporting,
    directToDeveloper,
    seamlessExperience,
    exdevDifference,
    howItWorks,
    popularQuestions,
    portfolio,
  } = paths

  return [
    {
      label: formatMessage({ id: "navbar.about.label" }),
      path: about,
      subMenuContent: [
        {
          path: about,
          label: formatMessage({
            id: "navbar.about.introduction.label",
          }),
          icon: "introduction",
          text: formatMessage({
            id: "navbar.about.introduction.text",
          }),
        },
        {
          path: aboutWhatWeDo,
          label: formatMessage({
            id: "navbar.about.whatWeDo.label",
          }),
          icon: "business",
          text: formatMessage({
            id: "navbar.about.whatWeDo.text",
          }),
        },
        {
          path: aboutSeeOurImpact,
          label: formatMessage({
            id: "navbar.about.ourImpact.label",
          }),
          icon: "impact",
          text: formatMessage({
            id: "navbar.about.ourImpact.text",
          }),
        },
        {
          path: aboutSurveyOurProcess,
          label: formatMessage({
            id: "navbar.about.surveyOurProcess.label",
          }),
          icon: "survey",
          text: formatMessage({
            id: "navbar.about.surveyOurProcess.text",
          }),
        },
        {
          path: blog,
          label: formatMessage({
            id: "navbar.about.blog.label",
          }),
          icon: "blogNav",
          text: formatMessage({
            id: "navbar.about.blog.text",
          }),
        },
      ],
    },
    {
      label: formatMessage({ id: "navbar.invest.label" }),
      path: investInCRE,
      subMenuContent: [
        {
          path: investInCRE,
          label: formatMessage({
            id: "navbar.invest.introduction.label",
          }),
          icon: "introduction",
          text: formatMessage({
            id: "navbar.invest.introduction.text",
          }),
        },
        {
          path: whyExdev,
          label: formatMessage({
            id: "navbar.invest.whyExdev.label",
          }),
          icon: "whyExdev",
          text: formatMessage({
            id: "navbar.invest.whyExdev.text",
          }),
        },
        {
          path: frontrowReporting,
          label: formatMessage({
            id: "navbar.invest.frontrowReporting.label",
          }),
          icon: "frontrow",
          text: formatMessage({
            id: "navbar.invest.frontrowReporting.text",
          }),
        },
        {
          path: directToDeveloper,
          label: formatMessage({
            id: "navbar.invest.directToDeveloper.label",
          }),
          icon: "directToDeveloper",
          text: formatMessage({
            id: "navbar.invest.directToDeveloper.text",
          }),
        },
        {
          path: seamlessExperience,
          label: formatMessage({
            id: "navbar.invest.seamlessExperience.label",
          }),
          icon: "digitalExperience",
          text: formatMessage({
            id: "navbar.invest.seamlessExperience.text",
          }),
        },
        {
          path: exdevDifference,
          label: formatMessage({
            id: "navbar.invest.exdevDifference.label",
          }),
          icon: "exNav",
          text: formatMessage({
            id: "navbar.invest.exdevDifference.text",
          }),
        },
        {
          path: howItWorks,
          label: formatMessage({
            id: "navbar.invest.howItWorks.label",
          }),
          icon: "infoNav",
          text: formatMessage({
            id: "navbar.invest.howItWorks.text",
          }),
        },
        {
          path: popularQuestions,
          label: formatMessage({
            id: "navbar.invest.popularQuestions.label",
          }),
          icon: "popularQuestions",
          text: formatMessage({
            id: "navbar.invest.popularQuestions.text",
          }),
        },
      ],
    },
    {
      label: formatMessage({ id: "navbar.portfolio" }),
      path: portfolio,
    },
  ]
}

export default useNavigationContent
