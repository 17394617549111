import React from "react"
import PropTypes from "prop-types"
import trackCustomEventTrigger from "utils/trackCustomEventTrigger"
import trackingCategoriesHelper from "utils/trackingCategoriesHelper"
import BtnLink from "components/atoms/BtnLink"

import SubMenuContent from "./SubMenuContent"
import { StyledNavItem, linkStyles } from "./LinkItem.styles"

const LinkItem = ({ linkProps: { label, subMenuContent, path } }) => {
  return (
    <StyledNavItem>
      <BtnLink
        to={path || ""}
        extraStyles={linkStyles}
        onClick={() =>
          trackCustomEventTrigger({
            category: trackingCategoriesHelper.GLOBAL_NAVIGATION,
            action: "Click",
            label: `${trackingCategoriesHelper.GLOBAL_NAVIGATION} link(${label})`,
          })
        }
      >
        {label}
      </BtnLink>
      {subMenuContent && (
        <SubMenuContent content={subMenuContent} parentLabel={label} />
      )}
    </StyledNavItem>
  )
}

LinkItem.propTypes = {
  linkProps: PropTypes.shape({
    path: PropTypes.string,
    label: PropTypes.string,
    subMenuContent: PropTypes.array,
  }).isRequired,
}

export default LinkItem
