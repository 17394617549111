import React from "react"
import PropTypes from "prop-types"
import trackingCategoriesHelper from "utils/trackingCategoriesHelper"

import { StyledList, StyledLink, StyledLabel } from "./FooterColumn.styles"

const FooterColumn = ({ columnProps: { mainLabel, links } }) => {
  return (
    <>
      <StyledList>
        {mainLabel && (
          <li>
            <StyledLabel>{mainLabel.label}</StyledLabel>
          </li>
        )}
        {links.map((link) => (
          <li key={`${link.label}_list`}>
            <StyledLink
              to={link.path}
              state={link.state}
              trackingOptions={{
                category: trackingCategoriesHelper.FOOTER,
                action: "Click",
                label: `${trackingCategoriesHelper.FOOTER} link(${link.label})`,
              }}
            >
              {link.label}
            </StyledLink>
          </li>
        ))}
      </StyledList>
    </>
  )
}

FooterColumn.propTypes = {
  columnProps: PropTypes.shape({
    mainLabel: PropTypes.object,
    links: PropTypes.array.isRequired,
  }).isRequired,
}

export default FooterColumn
