import styled from "styled-components"
import CloseIcon from "components/atoms/CloseIcon"

const StyledBanner = styled.div`
  width: 100%;
  min-height: 5.5rem;
  z-index: 89;
  padding: 1.6rem 0;

  ${({ theme }) => theme.mq.medium} {
    flex-direction: row;
    padding: 0;
  }
`

const StyledContentWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 5.5rem;
  max-width: ${({ theme }) => theme.gridWidth};
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.layout.mobilePadding} 0 4rem;
  position: relative;
  justify-content: center;

  ${({ theme }) => theme.mq.medium} {
    padding: 0 ${({ theme }) => theme.layout.mobilePadding};
  }
`

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.mq.medium} {
    flex-direction: row;
  }
`

const StyledCloseIcon = styled(CloseIcon)`
  height: 2px;
  right: 0;
  left: initial;
  background: ${({ theme }) => theme.color.white};
`

const StyledCloseButton = styled.button`
  border: none;
  background: none;
  width: 3.5rem;
  height: 2rem;
  cursor: pointer;
  position: absolute;
  left: 0;
`

export {
  StyledBanner,
  StyledContentWrapper,
  StyledContent,
  StyledCloseIcon,
  StyledCloseButton,
}
