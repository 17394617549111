import Acquisition from "assets/icons/acquisition.svg"
import AssetManagement from "assets/icons/asset-management.svg"
import Briefing from "assets/icons/briefing.svg"
import Business from "assets/icons/business.svg"
import Frontrow from "assets/icons/frontrow.svg"
import BlogNav from "assets/icons/blog-nav.svg"
import Survey from "assets/icons/survey.svg"
import InfoNav from "assets/icons/info-nav.svg"
import ExNav from "assets/icons/ex-nav.svg"
import DigitalExperience from "assets/icons/digital-experience.svg"
import Report from "assets/icons/report.svg"
import DirectToDeveloper from "assets/icons/direct-to-developer.svg"
import Impact from "assets/icons/impact.svg"
import History from "assets/icons/history.svg"
import ChevronDown from "assets/icons/chevron-down.svg"
import ChevronLeft from "assets/icons/chevron-left.svg"
import ChevronRight from "assets/icons/chevron-right.svg"
import ChevronUp from "assets/icons/chevron-up.svg"
import ConstructionManagement from "assets/icons/construction-management.svg"
import Contact from "assets/icons/contact.svg"
import DesignEntitlement from "assets/icons/design-entitlement.svg"
import Financing from "assets/icons/financing.svg"
import Grid from "assets/icons/grid.svg"
import Info from "assets/icons/info.svg"
import Location from "assets/icons/location.svg"
import Gallery from "assets/icons/gallery.svg"
import Overview from "assets/icons/overview.svg"
import PhoneRotate from "assets/icons/phone-rotate.svg"
import Phone from "assets/icons/phone.svg"
import Progress from "assets/icons/progress.svg"
import SalesLeasing from "assets/icons/sales-leasing.svg"
import Send from "assets/icons/send.svg"
import Subscribe from "assets/icons/subscribe.svg"
import Snapshot from "assets/icons/snapshot.svg"
import Timeline from "assets/icons/timeline.svg"
import TriangleDown from "assets/icons/triangle-down.svg"
import TriangleUp from "assets/icons/triangle-up.svg"
import Vision from "assets/icons/vision.svg"
import Accessibility from "assets/icons/accessibility.svg"
import Highlights from "assets/icons/highlights.svg"
import Statistics from "assets/icons/statistics.svg"
import Traffic from "assets/icons/traffic.svg"
import ProjectType from "assets/icons/project-type.svg"
import Building from "assets/icons/building.svg"
import Land from "assets/icons/land.svg"
import Usage from "assets/icons/usage.svg"
import OneHourDrive from "assets/icons/oneHourDrive.svg"
import Market from "assets/icons/market.svg"
import Submarket from "assets/icons/submarket.svg"
import BlueCollarJobs from "assets/icons/blueCollarJobs.svg"
import SurveyRegionName from "assets/icons/surveyRegionName.svg"
import Population from "assets/icons/population.svg"
import PopulationDensity from "assets/icons/populationDensity.svg"
import MedianAge from "assets/icons/medianAge.svg"
import MedianNetWorth from "assets/icons/medianNetWorth.svg"
import MedianHouseholdIncome from "assets/icons/medianHouseholdIncome.svg"
import TotalNumberOfBusiness from "assets/icons/totalNumberOfBusiness.svg"
import TotalNumberOfEmployees from "assets/icons/totalNumberOfEmployees.svg"
import WhiteCollarJobs from "assets/icons/whiteCollarJobs.svg"
import ServiceJobs from "assets/icons/serviceJobs.svg"
import Cursor from "assets/icons/cursor.svg"
import Replace from "assets/icons/replace.svg"
import Filter from "assets/icons/filter.svg"
import PopularQuestions from "assets/icons/popular-questions.svg"
import Introduction from "assets/icons/introduction.svg"
import Reset from "assets/icons/reset-icon.svg"
import Map from "assets/icons/location-icon.svg"

// nav icons
import Blog from "assets/icons/blog.svg"
import BlogMobile from "assets/icons/blog-mob.svg"
import HelpLearning from "assets/icons/help-learning.svg"
import HelpLearningMobile from "assets/icons/help-learning-mob.svg"
import HowItWorks from "assets/icons/how-it-works.svg"
import HowItWorksMobile from "assets/icons/how-it-works-mob.svg"
import SeeOurImpact from "assets/icons/see-our-impact.svg"
import SeeOurImpactMobile from "assets/icons/see-our-impact-mob.svg"
import SurveyOurProcess from "assets/icons/survey-our-process.svg"
import SurveyOurProcessMobile from "assets/icons/survey-our-process-mob.svg"
import UnderstandOurBusiness from "assets/icons/understand-our-business.svg"
import UnderstandOurBusinessMobile from "assets/icons/understand-our-business-mob.svg"
import WhyExdev from "assets/icons/why-exdev.svg"
import WhyExdevMobile from "assets/icons/why-exdev-mob.svg"

// @TODO: We can possibly delete also this IconsMap
const Icons = {
  acquisition: Acquisition,
  introduction: Introduction,
  assetManagement: AssetManagement,
  briefing: Briefing,
  business: Business,
  blog: Blog,
  infoNav: InfoNav,
  exNav: ExNav,
  digitalExperience: DigitalExperience,
  report: Report,
  directToDeveloper: DirectToDeveloper,
  blogNav: BlogNav,
  survey: Survey,
  impact: Impact,
  frontrow: Frontrow,
  blogMobile: BlogMobile,
  chevronDown: ChevronDown,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  chevronUp: ChevronUp,
  popularQuestions: PopularQuestions,
  constructionManagement: ConstructionManagement,
  contact: Contact,
  designEntitlement: DesignEntitlement,
  financing: Financing,
  grid: Grid,
  helpLearning: HelpLearning,
  helpLearningMobile: HelpLearningMobile,
  history: History,
  howItWorks: HowItWorks,
  howItWorksMobile: HowItWorksMobile,
  info: Info,
  location: Location,
  map: Map,
  gallery: Gallery,
  overview: Overview,
  phoneRotate: PhoneRotate,
  phone: Phone,
  progress: Progress,
  salesLeasing: SalesLeasing,
  seeOurImpact: SeeOurImpact,
  seeOurImpactMobile: SeeOurImpactMobile,
  send: Send,
  snapshot: Snapshot,
  subscribe: Subscribe,
  surveyOurProcess: SurveyOurProcess,
  surveyOurProcessMobile: SurveyOurProcessMobile,
  timeline: Timeline,
  triangleDown: TriangleDown,
  triangleUp: TriangleUp,
  understandOurBusiness: UnderstandOurBusiness,
  understandOurBusinessMobile: UnderstandOurBusinessMobile,
  whyExdev: WhyExdev,
  whyExdevMobile: WhyExdevMobile,
  vision: Vision,
  accessibility: Accessibility,
  highlights: Highlights,
  traffic: Traffic,
  statistics: Statistics,
  usage: Usage,
  projectType: ProjectType,
  building: Building,
  land: Land,
  oneHourDrive: OneHourDrive,
  submarket: Submarket,
  market: Market,
  blueCollarJobs: BlueCollarJobs,
  surveyRegionName: SurveyRegionName,
  population: Population,
  populationDensity: PopulationDensity,
  medianAge: MedianAge,
  medianNetWorth: MedianNetWorth,
  medianHouseholdIncome: MedianHouseholdIncome,
  totalNumberOfBusiness: TotalNumberOfBusiness,
  totalNumberOfEmployees: TotalNumberOfEmployees,
  whiteCollarJobs: WhiteCollarJobs,
  serviceJobs: ServiceJobs,
  cursor: Cursor,
  replace: Replace,
  filter: Filter,
  reset: Reset,
}

export default Icons
