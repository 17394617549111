import React from "react"
import Downshift from "downshift"
import PropTypes from "prop-types"

import trackCustomEventTrigger from "utils/trackCustomEventTrigger"
import trackingCategoriesHelper from "utils/trackingCategoriesHelper"

import ToggleButton from "./ToggleButton"
import { Menu, MenuItem } from "./Menu"

import { StyledSelectWrapper, StyledSelectButton } from "./Select.styles"

const Select = ({
  items,
  handleOnChange,
  currentValue,
  extraInputStyles,
  extraMenuStyles,
  extraMenuItemStyles,
  labelPrefix,
  testId,
}) => (
  <StyledSelectWrapper data-testid={testId}>
    <Downshift
      onChange={(selection) => {
        handleOnChange(selection)
      }}
      initialSelectedItem={currentValue || items[0]}
      itemToString={(item) => (item ? item.value : "")}
    >
      {({
        getItemProps,
        getMenuProps,
        toggleMenu,
        isOpen,
        selectedItem,
        highlightedIndex,
        getRootProps,
      }) => (
        <>
          <div data-testid="selectWrapper">
            <div
              style={{ display: "inline-block" }}
              {...getRootProps({}, { suppressRefError: true })}
              data-testid="selectButton"
            >
              <StyledSelectButton
                type="button"
                onClick={() => {
                  toggleMenu()
                  trackCustomEventTrigger({
                    category: trackingCategoriesHelper.FOOTER,
                    action: "Click",
                    label: `${trackingCategoriesHelper.FOOTER} link(Language menu)`,
                  })
                }}
                extraInputStyles={extraInputStyles}
                isOpen={isOpen}
                data-testid="selectLabel"
              >
                {`${labelPrefix} ${selectedItem.label}`}
                <ToggleButton isOpen={isOpen} />
              </StyledSelectButton>
            </div>
            <Menu
              isOpen={isOpen}
              extraMenuStyles={extraMenuStyles}
              {...getMenuProps()}
              data-testid="selectMenu"
            >
              {isOpen
                ? items.map((item, index) => (
                    <MenuItem
                      key={item.label}
                      extraMenuItemStyles={extraMenuItemStyles}
                      {...getItemProps({
                        index,
                        item,
                        isActive: highlightedIndex === index,
                        isSelected: selectedItem.value.includes(item.value),
                        onClick: () => {
                          trackCustomEventTrigger({
                            category: trackingCategoriesHelper.FOOTER,
                            action: "Click",
                            label: `${trackingCategoriesHelper.FOOTER} link(Language menu - ${item.label})`,
                          })
                        },
                      })}
                      data-testid="selectMenuItem"
                    >
                      {item.label}
                    </MenuItem>
                  ))
                : null}
            </Menu>
          </div>
        </>
      )}
    </Downshift>
  </StyledSelectWrapper>
)

Select.propTypes = {
  items: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  extraInputStyles: PropTypes.any,
  extraMenuStyles: PropTypes.any,
  extraMenuItemStyles: PropTypes.any,
  labelPrefix: PropTypes.string,
  testId: PropTypes.string.isRequired,
}

Select.defaultProps = {
  currentValue: null,
  extraInputStyles: null,
  extraMenuStyles: null,
  extraMenuItemStyles: null,
  labelPrefix: "",
}

export default Select
