import React from "react"
import PropTypes from "prop-types"

import { StyledButton, StyledIcon } from "./AccordionButton.styles"

const AccordionButton = ({
  children,
  handleTogglePanel,
  isOpen,
  btnStyles,
}) => {
  return (
    <StyledButton btnStyles={btnStyles} onClick={handleTogglePanel}>
      {children} <StyledIcon isOpen={isOpen} />
    </StyledButton>
  )
}

AccordionButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  handleTogglePanel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  btnStyles: PropTypes.any,
}

AccordionButton.defaultProps = {
  btnStyles: "",
}

export default AccordionButton
