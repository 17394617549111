import React from "react"
import PropTypes from "prop-types"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"
import browserWindow from "utils/browserWindow"
import Button from "components/atoms/Button"
import P from "components/atoms/P"
import {
  paragraphExtraStyles,
  StyledButtonWrapper,
  StyledBanner,
} from "./LanguageBanner.styles"

const LanguageBanner = ({ handleCloseLangBanner, suggestedLanguage }) => {
  const handleAccept = () => {
    browserWindow.localStorage.setItem("langBannerClicked", "true")
    changeLocale(suggestedLanguage.value)
    handleCloseLangBanner()
  }

  const handleCancel = () => {
    browserWindow.localStorage.setItem("langBannerClicked", "true")
    handleCloseLangBanner()
  }

  return (
    <StyledBanner
      onClose={() => handleCloseLangBanner()}
      testId="languageBanner"
    >
      <IntlContextConsumer>
        {() => (
          <>
            <P extraStyles={paragraphExtraStyles}>{suggestedLanguage.text}</P>
            <StyledButtonWrapper>
              <Button
                size="medium"
                type="tertiary"
                onClick={() => handleAccept()}
                testId="bannerAcceptButton"
              >
                {suggestedLanguage.acceptButton}
              </Button>
              <Button
                size="medium"
                type="secondary"
                hasDarkBg
                onClick={() => handleCancel()}
                testId="bannerCancelButton"
              >
                {suggestedLanguage.cancelButton}
              </Button>
            </StyledButtonWrapper>
          </>
        )}
      </IntlContextConsumer>
    </StyledBanner>
  )
}

LanguageBanner.propTypes = {
  handleCloseLangBanner: PropTypes.func.isRequired,
  suggestedLanguage: PropTypes.shape({
    text: PropTypes.string,
    acceptButton: PropTypes.string,
    cancelButton: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
}

export default LanguageBanner
