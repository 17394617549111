import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { ThemeProvider } from "styled-components"
import theme from "styles/theme"
import GlobalStyles from "styles/GlobalStyles"
import Navigation from "components/molecules/Navigation"
import Footer from "components/molecules/Footer"
import LanguageBanner from "components/molecules/LanguageBanner"
import GlobalBanner from "components/molecules/GlobalBanner"
import browserWindow from "utils/browserWindow"
import languageBannerHelper from "utils/languageBannerHelper"
import getGlobalAlert from "utils/getGlobalAlert"
import "pure-react-carousel/dist/react-carousel.es.css"
import { StyledMain, StyledBannerWrapper } from "./Layout.styles"

const Layout = ({
  children,
  isReverseColor,
  language,
  blockScrollAnimationOn,
  hideFooter,
  isNavStatic,
  setIsBannerActive,
}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const bannerRef = useRef(null)

  const [isLangBannerVisible, setIsLangBannerVisible] = useState(false)
  const [isGlobalBannerVisible, setIsGlobalBannerVisible] = useState(false)
  const [globalAlertData, setGlobalAlertData] = useState(null)
  const [bannerHeight, setBannerHeight] = useState(0)
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  const getAlertData = async () => {
    const response = await getGlobalAlert(language)

    const { globalAlertBanner } = response.data

    setGlobalAlertData(globalAlertBanner)
    setIsGlobalBannerVisible(globalAlertBanner.isPublished)
  }

  useEffect(() => {
    if (isLangBannerVisible || isGlobalBannerVisible) setIsBannerActive(true)
  }, [isLangBannerVisible, isGlobalBannerVisible])

  useEffect(() => {
    const isBannerClicked = browserWindow.sessionStorage.getItem(
      "langBannerClicked"
    )
    if (isBannerClicked === "true") {
      return
    }
    getAlertData()
  }, [])

  useEffect(() => {
    const isBannerClicked = browserWindow.localStorage.getItem(
      "langBannerClicked"
    )
    if (isBannerClicked === "true") {
      return
    }
    if (languageBannerHelper(language) !== null) {
      setIsLangBannerVisible(true)
    }
  }, [])

  useEffect(() => {
    if (bannerRef) {
      setBannerHeight(bannerRef.current.offsetHeight)
    }
  }, [bannerRef, isGlobalBannerVisible, isLangBannerVisible, isMobileMenuOpen])

  const handleCloseLangBanner = () => {
    setIsLangBannerVisible(false)
    setIsBannerActive(false)
  }

  const handleCloseGlobalBanner = () => {
    setIsGlobalBannerVisible(false)
    setIsBannerActive(false)
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <StyledBannerWrapper ref={bannerRef}>
        {globalAlertData &&
          isGlobalBannerVisible &&
          !isLangBannerVisible &&
          !isMobileMenuOpen && (
            <GlobalBanner
              handleClose={handleCloseGlobalBanner}
              alertData={globalAlertData}
            />
          )}
        {isLangBannerVisible && !isMobileMenuOpen && (
          <LanguageBanner
            handleCloseLangBanner={handleCloseLangBanner}
            suggestedLanguage={languageBannerHelper(language)}
          />
        )}
      </StyledBannerWrapper>
      <Navigation
        siteTitle={data.site.siteMetadata.title}
        isReverseColor={isReverseColor}
        blockScrollAnimationOn={blockScrollAnimationOn}
        isNavStatic={isNavStatic}
        bannerHeight={bannerHeight}
        onMenuAction={setIsMobileMenuOpen}
      />
      <StyledMain bannerHeight={bannerHeight}>
        {children &&
          children.map((child) => {
            if (child) {
              const newProps = { ...child.props, bannerHeight }
              const preparedChild = { ...child, props: newProps }
              return preparedChild
            }
            return child
          })}
      </StyledMain>
      {!hideFooter && <Footer />}
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isReverseColor: PropTypes.bool,
  hideFooter: PropTypes.bool,
  blockScrollAnimationOn: PropTypes.shape({
    mobileLandscape: PropTypes.bool,
    mobilePortrait: PropTypes.bool,
    desktop: PropTypes.bool,
  }),
  isNavStatic: PropTypes.bool,
  language: PropTypes.string,
  setIsBannerActive: PropTypes.func,
}

Layout.defaultProps = {
  isReverseColor: false,
  hideFooter: false,
  blockScrollAnimationOn: {
    mobileLandscape: false,
    mobilePortrait: false,
    desktop: false,
  },
  isNavStatic: false,
  language: "",
  setIsBannerActive: () => {},
}
export default Layout
