import styled from "styled-components"
import BtnLink from "components/atoms/BtnLink"

const StyledLabel = styled.span`
  align-self: baseline;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.color.white};
  text-decoration: none;
  margin: 0.8rem 0;
  display: inline-block;

  ${({ theme }) => theme.mq.medium} {
    margin: 0.8rem 0 3.2rem;
  }
`

const StyledList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 2rem 1.2rem;
  display: flex;
  flex-direction: column;
  width: calc(50% - 1.6rem);

  * {
    font-size: ${({ theme }) => theme.font.size.s};
    font-weight: ${({ theme }) => theme.font.weight.semiBold};
    line-height: 2.4rem;
    text-align: left;
  }

  &:nth-of-type(1),
  &:nth-of-type(2) {
    width: 100%;
  }

  ${({ theme }) => theme.mq.medium} {
    &:nth-of-type(1) {
      width: 100%;
    }
    &:nth-of-type(2) {
      width: 70%;
    }
    &:nth-of-type(3) {
      width: 50%;
    }
    &:nth-of-type(4) {
      width: 30%;
    }
  }
`

const StyledLink = styled(BtnLink)`
  align-self: baseline;
  color: ${({ theme }) => theme.color.secondary03};
  text-decoration: none;
  margin: 0.8rem 0;
  display: inline-block;
`

export { StyledList, StyledLink, StyledLabel }
