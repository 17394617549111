import React from "react"
import PropTypes from "prop-types"
import BtnLink from "components/atoms/BtnLink"
import trackCustomEventTrigger from "utils/trackCustomEventTrigger"
import trackingCategoriesHelper from "utils/trackingCategoriesHelper"

import P from "components/atoms/P"
import Icon from "components/atoms/Icon"
import {
  StyledSubMenuWrapper,
  StyledList,
  StyledListItem,
  StyledHeader,
  StyledImgWrapper,
  StyledIconWrapper,
  menuButtonStyles,
} from "./SubMenuContent.styles"

const SubMenuContent = ({ content, parentLabel }) => {
  return (
    <StyledSubMenuWrapper className="subMenuContent">
      <div>
        <StyledList>
          {content.map((el) => (
            <StyledListItem key={el.label}>
              <BtnLink
                to={el.path}
                extraStyles={menuButtonStyles}
                onClick={() =>
                  trackCustomEventTrigger({
                    category: trackingCategoriesHelper.GLOBAL_NAVIGATION,
                    action: "Click",
                    label: `${trackingCategoriesHelper.GLOBAL_NAVIGATION} link(${parentLabel} - ${el.label})`,
                  })
                }
              >
                <StyledImgWrapper>
                  <StyledIconWrapper>
                    {el.icon && <Icon icon={el.icon} />}
                  </StyledIconWrapper>
                </StyledImgWrapper>
                <div>
                  <StyledHeader>{el.label}</StyledHeader>
                  <P>{el.text}</P>
                </div>
              </BtnLink>
            </StyledListItem>
          ))}
        </StyledList>
      </div>
    </StyledSubMenuWrapper>
  )
}

SubMenuContent.propTypes = {
  content: PropTypes.array.isRequired,
  parentLabel: PropTypes.string,
}

SubMenuContent.defaultProps = {
  parentLabel: "",
}

export default SubMenuContent
