import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import TriangleUp from "assets/icons/triangle-up.svg"
import TriangleDown from "assets/icons/triangle-down.svg"

const ToggleButtonContainer = styled.div`
  width: 1.6rem;
  height: 1.6rem;
  display: flex;
`

const ToggleButton = ({ isOpen, getToggleButtonProps }) => {
  const altText = isOpen ? "Close" : "Open"
  const arrowIcon = isOpen ? (
    <TriangleUp alt={altText} />
  ) : (
    <TriangleDown alt={altText} />
  )

  return (
    <ToggleButtonContainer
      data-testid="selectToggleArrow"
      tabIndex="0"
      {...getToggleButtonProps()}
    >
      {arrowIcon}
    </ToggleButtonContainer>
  )
}

ToggleButton.propTypes = {
  isOpen: PropTypes.bool,
  getToggleButtonProps: PropTypes.func,
}

ToggleButton.defaultProps = {
  isOpen: false,
  getToggleButtonProps: () => {},
}

export default ToggleButton
