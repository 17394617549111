import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import paths from "utils/paths"
import useWindowSize from "utils/useWindowSize"
import trackCustomEventTrigger from "utils/trackCustomEventTrigger"
import trackingCategoriesHelper from "utils/trackingCategoriesHelper"

import theme from "styles/theme"
import Logo from "components/atoms/Logo"

import DesktopNav from "./DesktopNav"
import Hamburger from "./Hamburger"
import MobileMenu from "./MobileMenu"
import {
  StyledHeader,
  StyledLink,
  logoStyles,
  StyledWrapper,
} from "./Navigation.styles"

const Navigation = ({
  isReverseColor,
  blockScrollAnimationOn,
  isNavStatic,
  bannerHeight,
  onMenuAction,
}) => {
  const size = useWindowSize()
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const [isWindowScrolled, setisWindowScrolled] = useState(false)

  const { home } = paths

  const toggleBackgroundOpacity = () => {
    const scrollPosition = window.scrollY
    setisWindowScrolled(scrollPosition >= 30)
  }

  useEffect(() => {
    window.addEventListener("scroll", toggleBackgroundOpacity)
    return () => window.removeEventListener("scroll", toggleBackgroundOpacity)
  }, [])

  useEffect(() => {
    onMenuAction(isMobileMenuOpen)
  }, [isMobileMenuOpen])

  const toggleMenu = () => {
    setIsMobileMenuOpen((oldVal) => !oldVal)
    onMenuAction()
  }

  return (
    <>
      <StyledHeader
        isOpened={isMobileMenuOpen}
        isScrolled={isWindowScrolled}
        isReverseColor={isReverseColor}
        blockScrollAnimationOn={blockScrollAnimationOn}
        isNavStatic={isNavStatic}
        data-testid="navigation"
        bannerHeight={bannerHeight}
      >
        <StyledWrapper>
          <StyledLink
            aria-label="home page link"
            to={home}
            onClick={() =>
              trackCustomEventTrigger({
                category: trackingCategoriesHelper.GLOBAL_NAVIGATION,
                action: "Click",
                label: `${trackingCategoriesHelper.GLOBAL_NAVIGATION} link(exdev logo)`,
              })
            }
          >
            <Logo extrastyles={logoStyles} />
          </StyledLink>
          <Hamburger
            isOpen={isMobileMenuOpen}
            toggleMenu={toggleMenu}
            isReverseColor={isReverseColor}
          />
          {size.width >= theme.breakpoints.medium && (
            <DesktopNav blockScrollAnimation={blockScrollAnimationOn.desktop} />
          )}
        </StyledWrapper>
      </StyledHeader>
      {size.width < theme.breakpoints.medium && (
        <MobileMenu
          isExpanded={isMobileMenuOpen}
          toggleMenu={toggleMenu}
          blockScrollAnimationOn={blockScrollAnimationOn}
        />
      )}
    </>
  )
}

Navigation.propTypes = {
  isReverseColor: PropTypes.bool,
  blockScrollAnimationOn: PropTypes.shape({
    mobileLandscape: PropTypes.bool,
    mobilePortrait: PropTypes.bool,
    desktop: PropTypes.bool,
  }),
  isNavStatic: PropTypes.bool,
  bannerHeight: PropTypes.number.isRequired,
  onMenuAction: PropTypes.func,
}

Navigation.defaultProps = {
  isReverseColor: false,
  blockScrollAnimationOn: {
    mobileLandscape: false,
    mobilePortrait: false,
    desktop: false,
  },
  isNavStatic: false,
  onMenuAction: () => null,
}

export default Navigation
