import styled, { css } from "styled-components"

const stageStatuses = {
  COMPLETE: css`
    color: ${({ theme }) => theme.color.quinary03};
  `,
  IN_PROGRESS: css`
    color: ${({ theme }) => theme.color.main03};
  `,
  PENDING: css`
    color: ${({ theme }) => theme.color.secondary02};
  `,
}

const StyledStagesLabel = styled.div`
  display: flex;
  align-items: center;

  font-size: ${({ theme }) => theme.font.size.s};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  line-height: 2.4rem;
  margin-bottom: 0.4rem;

  ${({ status }) => stageStatuses[status]}

  svg {
    width: 1.7rem;
    height: 1.6rem;
    margin-right: 0.8rem;
  }
`

export { StyledStagesLabel }
